.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  margin-left: -80px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Optional: Adjust icon size and style */
.fas.fa-info-circle {
  font-size: 20px;
  color: #333;
}

/* Add this to your App.css or a relevant CSS file */
.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.card-body {
  padding: 0.5rem;
}


.form-label {
  font-weight: bold;
}

button {
  background-color: #007bff;
  border: none;
}

button:hover {
  background-color: #0056b3;
}

.dropdown {
  margin-right: auto;
}

.close-button {
  margin-left: 30px; /* Adjust the spacing as needed */
}

.header-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* Modal Styles */
.devmodal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.devmodal-content {
  background-color: white;
  width: 60%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 20vh;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.devclose {
  float: right;
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
}

.checkAll
{
  width: 10px;
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.checkbox-container label {
  /* Ensures the text within the label wraps */
  word-wrap: break-word;
  /* Optional: Defines a maximum width for the label to prevent overly long lines */
  max-width: 300px; /* Adjust based on your design requirements */
}

.checkbox-container input[type="checkbox"] {
  /* Places the checkbox below the label and centers it */
  margin-top: 10px; /* Adjust the spacing between the label and the checkbox */
}

.header-title
{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

ul.no-bullets {
  list-style-type: none;
  padding-left: 0;
}

.btn-zelo-close {
  border: none !important;
  background-color: white;
}

.zelo_tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.zelo_tooltip .zelo_tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.zelo_tooltip:hover .zelo_tooltiptext {
  visibility: visible;
  opacity: 1;
}

.custom-info-icon {
  font-size: 1rem; /* Adjust the font size as needed */
  color: blue; /* Change the color to blue */
}

.zelo-text-container {
  width: 50%;
  margin: auto;
  padding: 20px;
}

.zelo-text-content {
  max-height: 3em; /* Limit the height to 3 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}

.zelo-text-content.expanded {
  white-space: normal;
  text-overflow: clip;
  max-height: none;
}

#zelo-toggle-button {
  margin-top: 10px;
  display: block;
}

.tara-alert {
  position: fixed;
  top: 60px; /* Adjust based on your navbar height */
  right: 20px;
  z-index: 1050; /* Ensure it's above other elements */
  width: auto; /* Adjust width as needed */
}

.v-hidden-row {
  display: none;
}
.v-dropdown-arrow::after {
  content: '\25BC'; /* Unicode for down arrow */
  font-size: 12px;
  margin-left: 5px;
  display: inline-block;
  transition: transform 0.3s;
}
.v-dropdown-arrow.expanded::after {
  transform: rotate(180deg); /* Rotate arrow up when expanded */
}
.v-toggle-row {
  background: none;
  border: none;
  color: #aeb2b6;
  padding: 0;
  font-size: 16px;
}
.v-toggle-row:hover {
  text-decoration: underline;
}
.v-toggle-row:focus {
  outline: none;
}
/* styles.css */
.confirmDialog {
  display: none;
  position: absolute;
  border: 1px solid #ccc;
  padding: 10px;
  background: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  z-index: 2000;
}

th, td {
border: 1px solid #ddd;
padding: 8px;
text-align: left;
}

.status-toggle {
width: 20px; /* Adjust width as needed */
}

.status-text {
width: 120px; /* Adjust width as needed */
}

.status-toggle th,td {
width: 20px; /* Adjust width as needed */
}

.status-text td, th {
width: 120px; /* Adjust width as needed */
}

.dynamic-column {
width: auto;
}

.status-toggle input[type="checkbox"] {
  width: 20px; /* Increase size for better visibility */
  height: 20px;
  border: 2px solid #333; /* Darker border for visibility */
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* Remove default styling for Safari */
  cursor: pointer; /* Change cursor on hover */
  display: inline-block;
  background-color: #fff;
  margin-right: 10px;
}

.status-toggle input[type="checkbox"]:checked {
  background-color: #4caf50; /* Change background when checked */
  border-color: #4caf50; /* Change border color when checked */
}

.status-toggle label {
  cursor: pointer; /* Make label clickable */
  line-height: 20px; /* Align text with checkbox */
}
.status-toggle th,td {
  justify-content: center; /* Center children horizontally */
  padding: 10px;
  vertical-align: top;
}

.toggle-all-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally within the <th> */
}

.toggle-all-container input[type="checkbox"] {
  width: 20px; /* Set the width of the checkbox */
  height: 20px; /* Set the height of the checkbox */
  margin-right: 5px; /* Space between checkbox and label */
  appearance: none; /* Remove default styling */
  -webkit-appearance: none; /* Remove default styling for Safari */
  background-color: #fff;
  border: 2px solid #333; /* Darker border for better visibility */
  cursor: pointer;
  display: inline-block;
}

.toggle-all-container input[type="checkbox"]:checked {
  background-color: #4caf50; /* Green background when checked */
  border-color: #4caf50; /* Green border when checked */
}

.toggle-all-container label {
  cursor: pointer;
  font-weight: bold; /* Make the label bold */
}

.ghost-text {
  color: darkgray;
  opacity: 0.5;   /* Makes the text semi-transparent */
  font-style: italic; /* Optional: adds an italic style */
}

/* Modal Overlay */

.modal-overlay {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: none;
justify-content: center;
align-items: center;
z-index: 1000;
}

/* General Styles for the Modal Window */

.window-container {
width: 90%;
max-width: 90%;
height: 90%;
max-height: 90%;
border: 1px solid #ccc;
border-radius: 6px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
overflow: hidden;
background-color: #fff;
font-family: Arial, sans-serif;
position: relative;
}

.window-container-vuln
{
height: 90%;
}

.window-header {
background-color: #f5f5f5;
padding: 10px;
display: flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid #ddd;
}

.table-container {
min-height: 400px; /* Set the desired height for the scrollable area */
max-height: 400px; /* Set the desired height for the scrollable area */
overflow-y: auto; /* Enable vertical scrolling */
-webkit-overflow-scrolling: touch; /* Add this line */
}

.dialog-container {
max-height: 400px; /* Set the desired height for the scrollable area */
overflow-y: auto; /* Enable vertical scrolling */
-webkit-overflow-scrolling: touch; /* Add this line */
}

.dialog-container-vuln {
max-height: 90%; /* Set the desired height for the scrollable area */
}

table {
width: 100%;
border-collapse: collapse;
}

thead {
background-color: #f2f2f2; /* Background color for the header */
position: sticky;
top: 0; /* Fixes the header at the top */
z-index: 2; /* Ensure this value is greater than any other z-index in the table */
}

th,
td {
padding: 8px 12px;
text-align: left;
border-bottom: 1px solid #ddd;
}

tbody tr:hover {
background-color: #f5f5f5; /* Highlight row on hover */
}



/* Dropdown Menu Styles */

.dropdown {
position: relative;
display: inline-block;
}

.dropdown-button {
background-color: #fff;
color: #333;
padding: 8px 16px;
font-size: 14px;
border: none;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.3s;
}

.dropdown-button:hover {
background-color: #e0e0e0;
}

.dropdown-content {
display: none;
position: absolute;
background-color: #fff;
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
border-radius: 4px;
z-index: 1;
}

.dropdown-content a {
color: #333;
padding: 12px 16px;
text-decoration: none;
display: block;
border-bottom: 1px solid #ddd;
}

.dropdown-content a:hover {
background-color: #f5f5f5;
}

.dropdown:hover .dropdown-content {
display: block;
}

/* Close Button Styles */

.close-button {
background-color: transparent;
color: #333;
font-size: 24px;
border: none;
cursor: pointer;
transition: color 0.3s;
}

.close-button:hover {
color: #d9534f;
}

/* Button to Open Modal */

.open-modal-button {
padding: 10px 20px;
font-size: 16px;
cursor: pointer;
border: none;
border-radius: 4px;
background-color: #5bc0de;
color: white;
transition: background-color 0.3s;
}

.open-modal-button:hover {
background-color: #31b0d5;
}

/* Form Actions Styles */

.form-actions {
padding: 10px;
border-top: 1px solid #ddd;
display: flex;
justify-content: flex-end;
background-color: #f9f9f9;
}

.submit-button,
.cancel-button {
padding: 8px 16px;
font-size: 14px;
border: none;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.3s;
margin-left: 10px;
}

.submit-button {
background-color: #5cb85c;
color: white;
}

.submit-button:hover {
background-color: #4cae4c;
}

.cancel-button {
background-color: #d9534f;
color: white;
}

.cancel-button:hover {
background-color: #c9302c;
}

/* Modal styles */
.xmodal {
display: none; /* Hidden by default */
position: fixed; /* Stay in place */
z-index: 1000; /* Sit on top */
left: 0;
top: 0;
width: 80%;
height: 80%;
overflow: auto; /* Enable scroll if needed */
background-color: rgba(0, 0, 0, 0.5); /* Black w/ opacity */
}

/* Modal content */
.xmodal-content {
background-color: #fefefe;
margin: 15% auto; /* Center vertically and horizontally */
padding: 20px;
border: 1px solid #888;
width: 90%; /* Responsive width */
height: 90%;
max-width: 600px; /* Max width for larger screens */
box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Close button */
.close {
color: #aaa;
float: right;
font-size: 28px;
font-weight: bold;
}

.close:hover,
.close:focus {
color: black;
text-decoration: none;
cursor: pointer;
}

.left-cell {
text-align: left; /* Center text horizontally */
}

.right-cell {
text-align: right; /* Center text horizontally */
}

.centered-cell {
text-align: center; /* Center text horizontally */
}

.centered-badge {
display: inline-block;
}

.left-content {
  text-align: left; /* Center text horizontally */
}
  
.right-content {
  text-align: right; /* Center text horizontally */
}
  
.centered-content {
  text-align: center; /* Center text horizontally */
}
  
.centered-badge {
  display: inline-block;
}

th {
cursor: pointer;
}

th.ascending::after {
content: ' ▲';
}

th.descending::after {
content: ' ▼';
}

.admin-item {
  color: red !important;
  display: inline-flex;
  align-items: center;
}

.admin-item::before {
  content: ' ►';
  font-weight: 900;
  color: inherit;
}

.csv-item {
  color: green !important;
  display: inline-flex;
  align-items: center;
}

.csv-item::before {
  content: '\f6dd'; /* Unicode for FontAwesome CSV icon */
  font-family: 'Font Awesome 5 Free'; /* Ensure you use the correct FontAwesome family */
  font-weight: 900; /* Use solid icon style */
  color: inherit; /* Inherits the red color */
  margin-right: 8px; /* Add spacing between icon and text */
}

.json-item {
  color: green !important;
  display: inline-flex;
  align-items: center;
}

.json-item::before {
  content: '\f1c9'; /* Unicode for FontAwesome Code File icon (faFileCode) */
  font-family: 'Font Awesome 5 Free'; /* Ensure you use the correct FontAwesome family */
  font-weight: 900; /* Use solid icon style */
  color: inherit; /* Inherits the green color */
  margin-right: 8px; /* Add spacing between icon and text */
}

.delete-item {
  color: red !important;
  display: inline-flex;
  align-items: center;
}

.delete-item::before {
  content: '\f1f8'; /* Unicode for FontAwesome Trash icon (faTrash) */
  font-family: 'Font Awesome 5 Free'; /* Ensure you use the correct FontAwesome family */
  font-weight: 900; /* Use solid icon style */
  color: inherit; /* Inherits the red color */
  margin-right: 8px; /* Add spacing between icon and text */
}

.new-item {
  color: red !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%; /* Ensure it occupies the container width if needed */
}

.new-item::before {
  content: '\f071'; /* Unicode for FontAwesome Trash icon (faTrash) */
  font-family: 'Font Awesome 5 Free'; /* Ensure you use the correct FontAwesome family */
  font-weight: 900; /* Use solid icon style */
  color: inherit; /* Inherits the red color */
  margin-right: 8px; /* Add spacing between icon and text */
}


.hamburger-icon {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 24px;
  height: 24px;
  padding: 0;
}

.hamburger-icon .bar {
  width: 24px;
  height: 3px;
  background-color: black;
  margin: 2px 0;
}

.zelo-border {
  border: 1px solid #ccc; 
  margin: 12px 0;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
}

footer {
  background-color: #343a40;
  color: white;
  padding: 20px 0;
}

.function-button-group {
    position: absolute;    /* Position the div absolutely */
    bottom: 10px;          /* Distance from the bottom */
    right: 10px;           /* Distance from the right */
    display: flex;         /* Use flexbox to align children */
    justify-content: flex-end; /* Align content to the right */
}

.my-auto {
  margin-top: 30px !important;  /* Adjust values as necessary */
  margin-bottom: 30px !important;
}

.summary-item 
{
  border: none !important;
}

.no-border {
  border: none !important;
  box-shadow: none !important;
}

.tara-card {
  max-width: 100em;
}

.modal {
  z-index: 2050 !important; /* Ensure the modal appears above the ag-grid */
}

.modal-backdrop {
  z-index: 2040 !important; /* Ensure the backdrop is correctly behind the modal */
}

.terms-container {
  max-width: 80rem;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  text-align: left;
}

.eula {
  width: 80%;
  margin: auto;
  font-family: Source Sans Pro, sans-serif;
  font-size:16px;
  color:#313131;
}

.eula-button-container {
  display: flex;
  margin-top: 20px;
  gap: 10px; /* Adds space between buttons */
  justify-content: center; /* Center-aligns buttons */
}

.eula-button {
  padding: 10px 20px;
}

.custom-popover .popover-body {
  max-height: 200px; /* Set max height for scrollable area */
  overflow-y: auto;  /* Enable vertical scrolling */
  max-width: 800px; /* Optional: Control width as needed */
  white-space: normal;
  font-size: 14px; /* Optional: Adjust font size */
  padding: 10px;
}

/* Make modal body more horizontal */
.modal-content {
  width: 90vw;
  max-width: 90vw; /* Makes modal almost full screen width */
  height: auto;
}

/* Define a horizontal flex layout with flex-wrap to handle overflow */
.vuln-horizontal-layout .row {
  display: flex;
  flex-wrap: wrap; 
  justify-content: flex-start;
  gap: 1rem;
}

.vuln-horizontal-layout .col {
  flex: 1 0 20%; /* Customize card width for horizontal appearance */
  min-width: 150px;
}

/* Ensure vertical scroll if content overflows vertically */
.vuln-modal-body {
  overflow-y: auto;
  max-height: 70vh; /* Adjust to your desired height */
}

/* Flex container for cards */
.vuln-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
}

/* Uniform Card Sizing */
.vuln-cards-container > div {
  width: 250px;        /* Fixed width for each card */
  height: 100px;       /* Fixed height for each card */
  max-width: 250px;        /* Fixed width for each card */
  max-height: 100px;       /* Fixed height for each card */
  overflow: auto;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
}

.card-alert-danger {
  background-color: #f8d7da;  /* Matches alert-danger background */
  color: #721c24;             /* Matches alert-danger text color */
  border-color: #f5c6cb;      /* Matches alert-danger border color */
}

.download-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #007BFF;
  font-size: 1rem;
}

.download-link:hover {
  text-decoration: underline;
}

.download-link .fa-download {
  margin-right: 8px;
}