.unauthorized-page {
    text-align: center;
    padding: 50px;
}

.unauthorized-page h1 {
    font-size: 2.5rem;
    color: red;
}

.unauthorized-page p {
    margin: 20px 0;
}

.unauthorized-page a {
    color: blue;
    text-decoration: underline;
    font-weight: bold;
}