.dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 250px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .dropdown-menu.show {
    visibility: visible;
    opacity: 1;
  }
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #007bff;
    color: white;
  }
  
  select {
    -webkit-appearance: none; /* For Chrome */
    -moz-appearance: none; /* For Firefox */
    appearance: none; /* For modern browsers */
    background: none;
    border: none;
    font-size: 24px; /* Adjust size for the three dots */
  }
  
  select::-ms-expand {
    display: none; /* For IE10 and IE11 */
  }

  .pcnt80
  {
    position: absolute;
    width: 80%;
    left: 50%;
    transform: translate(-50%,0%);
  }