html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}

.tara-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.tara-open-modal-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.tara-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.tara-modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.tara-modal-dialog {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.tara-close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #f44336;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  margin-left: auto; 
}

.tara-modal-header {
  background-color: #f8f9fa;
  padding: 15px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.tara-scrollable-table {
  flex: 1;
  overflow-y: auto;
  margin: 20px 0;
  border: 1px solid #ccc;
}

.tara-table {
  width: 100%;
  border-collapse: collapse;
}

.tara-table {
  thead {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }

  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  tr {
    cursor: pointer;
    background-color: #f8f9fa;
  }

  /*tr:hover {
    //background-color: #8d9ee4;
  }*/
}

.tara-modal-footer {
  background-color: #f8f9fa;
  padding: 15px;
  text-align: center;
  border-top: 1px solid #ccc;
}

.StatusMenu {
  margin-right: auto; /* Push StatusMenu to the far left */
}

.HeaderBlock {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.checkbox-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 10px;
  border: 2px solid #f1e9e9;
  border-radius: 5px;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  padding: 5px;
}

input[type="checkbox"] {
  margin-right: 8px;
}

.ag-theme-alpine {
  font-size: 1.2rem;
}

.ag-theme-quartz {
  font-size: 26px;
}

.ag-left-align {
  text-align: left;
}

.ag-center-align {
  text-align: center;
}

.ag-right-align {
  text-align: right;
}

.grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.grid {
  width: 90%;
  max-width: 1200px;
}
